import type { Streamfield, WagtailImageRendition, WagtailSvg } from '@/types';
import Streamfields from '@streamfields/Streamfields';
import { BasePageProps, basePageWrap } from '@views/BasePage';
import React from 'react';
import Hero from '@components/Hero';
import BannerImage from '@components/BannerImage';
import clsx from 'clsx';

type Props = {
  svg: WagtailSvg;
  title: string;
  subheader: string;
  subtitle: string;
  content: Streamfield[];
  banner_image: WagtailImageRendition | null;
  mobile_banner_image: WagtailImageRendition | null;
} & BasePageProps;

function FlexPage(props: Props) {
  const {
    content,
    banner_image,
    mobile_banner_image,
    svg,
    subtitle,
    subheader,
    title,
    blocksLoaderData,
  } = props;

  return (
    <div className='flex-page'>
      <div
        className={clsx({
          'hero-content': banner_image,
        })}
      >
        {banner_image ? (
          <BannerImage image={banner_image} mobileImage={mobile_banner_image} />
        ) : (
          <div className='banner-image banner-image__small' />
        )}
        <Hero title={title} subtitle={subheader} text={subtitle} svg={svg} />
      </div>
      {content ? <Streamfields fields={content} blocksLoaderData={blocksLoaderData} /> : null}
    </div>
  );
}

export default basePageWrap<Props>(FlexPage);
